import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { PostUpdateTitle } from '../../components/post-update-title/post-update-title';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Wie fühlt es sich an, blind zu sein? Wie kann man auf einer Website navigieren und den Inhalt lesen?
Meine Demo-Seite „Komm auf die dunkle Seite“ ermöglicht es, alles schwarz zu färben und das Web wie
blinde Nutzer:innen zu erleben.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBP/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAE99ixBNFf/xAAbEAACAQUAAAAAAAAAAAAAAAABAhAAAxESIf/aAAgBAQABBQJrSgjZY48Yr//EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPwEE2f/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AWf/xAAaEAACAgMAAAAAAAAAAAAAAAABEAACESFR/9oACAEBAAY/AsVg4VsP/8QAGBABAQEBAQAAAAAAAAAAAAAAAREAITH/2gAIAQEAAT8hu5Gyy9xBnhiO4CVwDk3f/9oADAMBAAIAAwAAABDHD//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8Qc6Kj/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAECAQE/EEJlt//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQVFx0f/aAAgBAQABPxAcRjZqLXoMT5aPIdI/TIKhmwqOal9zBAQhwBowuhn/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Nahaufnahme eines menschlichen Auges",
          "title": "Nahaufnahme eines menschlichen Auges",
          "src": "/static/de7049abd912f7cb2473e2b20d75be07/e5166/pexels-victor-freitas-eye-closeup.jpg",
          "srcSet": ["/static/de7049abd912f7cb2473e2b20d75be07/f93b5/pexels-victor-freitas-eye-closeup.jpg 300w", "/static/de7049abd912f7cb2473e2b20d75be07/b4294/pexels-victor-freitas-eye-closeup.jpg 600w", "/static/de7049abd912f7cb2473e2b20d75be07/e5166/pexels-victor-freitas-eye-closeup.jpg 1200w", "/static/de7049abd912f7cb2473e2b20d75be07/b17f8/pexels-victor-freitas-eye-closeup.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Victor Freitas / pexels.com`}</em></p>
    <h2>{`2,2 Milliarden Menschen sind sehbeeinträchtigt`}</h2>
    <p>{`Laut dem `}<a parentName="p" {...{
        "href": "https://www.who.int/publications/i/item/9789241516570"
      }}><span lang="en">{`„World report on vision 2019“`}</span></a>{`
sind weltweit etwa 2,2 Milliarden Menschen blind oder sehbeeinträchtigt. Viele von ihnen verwenden einen
Screenreader, um im Internet zu surfen.`}</p>
    <p>{`Sehenden Menschen fällt es oft schwer, wirklich zu verstehen, was das bedeutet: Kein visueller Input, sich
nur auf das auditive Feedback des Screenreaders verlassen müssen.`}</p>
    <p>{`Da ich ein neugieriger Mensch bin, habe ich eine Demo-Seite zusammengestellt, auf der du erleben kannst,
wie sich das Web für blinde Nutzer:innen anfühlt. Mein Ziel ist es, dass Menschen selbst nachvollziehen
können, wie wichtig barrierefreie Web-Inhalte für blinde Menschen sind.`}</p>
    <h2>{`Komm auf die dunkle Seite`}</h2>
    <p>{`Ich habe meine `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/come-to-the-dark-side/home?lang=de"
      }}>{`Demo-Seite`}</a>{` unten eingebunden.
Aktiviere einfach deinen Screenreader und färbe über den „Licht aus“-Schalter in der Kopfzeile alles schwarz.`}</p>
    <iframe src="https://alexlehner86.github.io/come-to-the-dark-side/home?lang=de" title="Demo: Komm auf die dunkle Seite"></iframe>
    <p>{`Ich freue mich auf dein Feedback! Schreib mir einfach auf `}<a parentName="p" {...{
        "href": "https://tech.lgbt/@alex86"
      }}>{`Mastodon`}</a>{`.`}</p>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update am 09.04.2022</PostUpdateTitle>
    <p>{`Ich habe meine Demo-Seite nun zweisprachig gestaltet und die deutschsprachige Version hier im Artikel
eingebunden und verlinkt.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      